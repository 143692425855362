'use strict';

exports.__esModule = true;

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.generateId = generateId;
exports.getMethods = getMethods;
exports.getActionsArray = getActionsArray;
exports.evalAction = evalAction;
exports.evalMethod = evalMethod;
exports.stringify = stringify;
exports.getSeralizeParameter = getSeralizeParameter;
exports.getStackTrace = getStackTrace;

var _getParams = require('get-params');

var _getParams2 = _interopRequireDefault(_getParams);

var _jsan = require('jsan');

var _jsan2 = _interopRequireDefault(_jsan);

var _nonSecure = require('nanoid/non-secure');

var _nonSecure2 = _interopRequireDefault(_nonSecure);

var _serialize = require('remotedev-serialize/immutable/serialize');

var _serialize2 = _interopRequireDefault(_serialize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generateId(id) {
  return id || (0, _nonSecure2.default)(7);
}

function flatTree(obj) {
  var namespace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  var functions = [];
  Object.keys(obj).forEach(function (key) {
    var prop = obj[key];
    if (typeof prop === 'function') {
      functions.push({
        name: namespace + (key || prop.name || 'anonymous'),
        func: prop,
        args: (0, _getParams2.default)(prop)
      });
    } else if ((typeof prop === 'undefined' ? 'undefined' : _typeof(prop)) === 'object') {
      functions = functions.concat(flatTree(prop, namespace + key + '.'));
    }
  });
  return functions;
}

function getMethods(obj) {
  if ((typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) !== 'object') return undefined;
  var functions = void 0;
  var m = void 0;
  if (obj.__proto__) m = obj.__proto__.__proto__;
  if (!m) m = obj;

  Object.getOwnPropertyNames(m).forEach(function (key) {
    var propDescriptor = Object.getOwnPropertyDescriptor(m, key);
    if (!propDescriptor || 'get' in propDescriptor || 'set' in propDescriptor) return;
    var prop = m[key];
    if (typeof prop === 'function' && key !== 'constructor') {
      if (!functions) functions = [];
      functions.push({
        name: key || prop.name || 'anonymous',
        args: (0, _getParams2.default)(prop)
      });
    }
  });
  return functions;
}

function getActionsArray(actionCreators) {
  if (Array.isArray(actionCreators)) return actionCreators;
  return flatTree(actionCreators);
}

/* eslint-disable no-new-func */
var interpretArg = function interpretArg(arg) {
  return new Function('return ' + arg)();
};

function evalArgs(inArgs, restArgs) {
  var args = inArgs.map(interpretArg);
  if (!restArgs) return args;
  var rest = interpretArg(restArgs);
  if (Array.isArray(rest)) return args.concat.apply(args, rest);
  throw new Error('rest must be an array');
}

function evalAction(action, actionCreators) {
  if (typeof action === 'string') {
    return new Function('return ' + action)();
  }

  var actionCreator = actionCreators[action.selected].func;
  var args = evalArgs(action.args, action.rest);
  return actionCreator.apply(undefined, args);
}

function evalMethod(action, obj) {
  if (typeof action === 'string') {
    return new Function('return ' + action).call(obj);
  }

  var args = evalArgs(action.args, action.rest);
  return new Function('args', 'return this.' + action.name + '(args)').apply(obj, args);
}
/* eslint-enable */

function tryCatchStringify(obj) {
  try {
    return JSON.stringify(obj);
  } catch (err) {
    /* eslint-disable no-console */
    if (process.env.NODE_ENV !== 'production') console.log('Failed to stringify', err);
    /* eslint-enable no-console */
    return _jsan2.default.stringify(obj, null, null, { circular: '[CIRCULAR]' });
  }
}

function stringify(obj, serialize) {
  if (typeof serialize === 'undefined') {
    return tryCatchStringify(obj);
  }
  if (serialize === true) {
    return _jsan2.default.stringify(obj, function (key, value) {
      if (value && typeof value.toJS === 'function') return value.toJS();
      return value;
    }, null, true);
  }
  return _jsan2.default.stringify(obj, serialize.replacer, null, serialize.options);
}

function getSeralizeParameter(config, param) {
  var serialize = config.serialize;
  if (serialize) {
    if (serialize === true) return { options: true };
    if (serialize.immutable) {
      return {
        replacer: (0, _serialize2.default)(serialize.immutable, serialize.refs).replacer,
        options: serialize.options || true
      };
    }
    if (!serialize.replacer) return { options: serialize.options };
    return { replacer: serialize.replacer, options: serialize.options || true };
  }

  var value = config[param];
  if (typeof value === 'undefined') return undefined;
  console.warn('`' + param + '` parameter for Redux DevTools Extension is deprecated. Use `serialize` parameter instead: https://github.com/zalmoxisus/redux-devtools-extension/releases/tag/v2.12.1'); // eslint-disable-line

  if (typeof serializeState === 'boolean') return { options: value };
  if (typeof serializeState === 'function') return { replacer: value };
  return value;
}

function getStackTrace(config, toExcludeFromTrace) {
  if (!config.trace) return undefined;
  if (typeof config.trace === 'function') return config.trace();

  var stack = void 0;
  var extraFrames = 0;
  var prevStackTraceLimit = void 0;
  var traceLimit = config.traceLimit;
  var error = Error();
  if (Error.captureStackTrace) {
    if (Error.stackTraceLimit < traceLimit) {
      prevStackTraceLimit = Error.stackTraceLimit;
      Error.stackTraceLimit = traceLimit;
    }
    Error.captureStackTrace(error, toExcludeFromTrace);
  } else {
    extraFrames = 3;
  }
  stack = error.stack;
  if (prevStackTraceLimit) Error.stackTraceLimit = prevStackTraceLimit;
  if (extraFrames || typeof Error.stackTraceLimit !== 'number' || Error.stackTraceLimit > traceLimit) {
    var frames = stack.split('\n');
    if (frames.length > traceLimit) {
      stack = frames.slice(0, traceLimit + extraFrames + (frames[0] === 'Error' ? 1 : 0)).join('\n');
    }
  }
  return stack;
}